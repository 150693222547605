import * as React from 'react';
// importを追記
import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
// componentsを追記
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import nl2br from '../../components/extend';

const AboutEN = ({ data, location }) => {
const person = data.contentfulPerson;
return (
  <Layout>
    {/* meta要素 */}
    {/* (NULLの場合はgatsby-config.jsのsiteMetadataを使用する) */}
    <SEO
      title={person.name + " | TUREDUREGUSA, Modern translation"}
      description=""
      keyword=""
      site_name=""
      url=""
      og_title=""
      og_type=""
      og_image=""
      tw_cardType=""
      tw_userName=""
    />
    {/* html要素 */}
    <div className="g-container">
      <main className="g-pageMain" role="main">
        <div className="l-container">
          <div className="u-mt-x5">
            <p className="c-leadText">
              <strong>TUREDUREGUSA, Modern translation</strong>
            </p>
            <h1 className="c-headingLv1">{person.name}</h1>
            <div className="l-grid l-gapSmall">
              <div className="l-grid_item l-grid_item-30per">
                <div className="c-img">
                  <img src={person.image.gatsbyImageData.images.fallback.src} alt="" />
                </div>
              </div>
              <div className="l-grid_item l-grid_item-70per">
                <p className="c-text">
                  {nl2br(person.shortBio.shortBio)}
                </p>
                <p className="c-text"><Link to={`/about/`}>Japanese translation</Link></p>
              </div>
            </div>
            {console.log(person)}
            <div className="u-mt-x5">
              <p className="c-text">
                <Link to="/en/">Back to home</Link>
              </p>
            </div>
          </div>
          <div className="u-mt-x5 u-ta-right">
            <p className="c-pageTop" data-js-localscroll>
              <a href="#top">
                <img
                  className="i-label i-label-left"
                  src="/assets/img/icons/ic_tri_up.svg"
                  alt=""
                />
                Page top
              </a>
            </p>
          </div>
        </div>
        {/* /.l-container */}
      </main>
      {/* /.g-pageMain */}
    </div>
    {/* /.g-container */}
  </Layout>
);
};

export default AboutEN;

export const pageQuery = graphql`
query AboutQueryEN {
  contentfulPerson(
      name: {eq: "Yoshida Kenkoh"},
      node_locale: {eq: "en-US"}
    ) {
    id
    node_locale
    title
    name
    shortBio {
      id
      shortBio
    }
    company
    phone
    email
    facebook
    github
    twitter
    updatedAt
    spaceId
    parent {
      id
    }
    image {
      id
      node_locale
      gatsbyImageData
      title
      description
    }
  }
}
`;
